import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import '@ionic/react/css/core.css';
import { IonButton, IonGrid, IonRow, IonCol, IonCard, IonAlert } from '@ionic/react';
import Workshop from './Workshop';

const Workshops = (props) => {

  const lang = JSON.parse(localStorage.getItem('lang')) || {
    "en": {
      "workshops": "Workshops"
    }
  };

  let currLang = lang[props.lang];

  return (
    <>
      <IonCard class="loggedin">
        <h2>{currLang["workshops"]}</h2>
        {props.workshops.map((item, i) => (
          <Workshop key={i} data={item} language={props.lang} />
        ))}
      </IonCard>
    </>
  );
}

export default Workshops;
