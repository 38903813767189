import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { IonCardContent, useIonToast, IonCardSubtitle, IonCardTitle, IonCardHeader, IonCard, IonItem, IonInput, IonText, IonSelect, IonSelectOption } from '@ionic/react';

const UserData = (props) => {

    const allUser = JSON.parse(localStorage.getItem('all-user')) || {};
    const myId = localStorage.getItem('scaner-id');

    const [present] = useIonToast();
    const presentToast = (custommessage, customclass, customduration = 2000) => {
        present({
            message: custommessage,
            duration: customduration,
            position: 'bottom',
            className: customclass,
        });
    };

    const me = allUser.data.filter((item) => item.id == myId);
    //console.log(me, props.roles)
        ;
    if (myId > 0) {
        return (
            <>
                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>User editieren</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <IonItem>
                            <IonInput id="updateUserName" type="text" labelPlacement="floating" placeholder="Username" value={me[0].name}><div slot="label">Username <IonText color="danger">(req)</IonText></div></IonInput>
                        </IonItem>
                        <IonItem>
                            <IonInput id="updateUserPassword" type="text" labelPlacement="floating" placeholder="Passwort" value={""}><div slot="label">Passwort <IonText color="danger">(only on change, otherwise left empty)</IonText></div></IonInput>
                        </IonItem>
                        <IonItem>
                            <IonSelect id="updateUserRole" labelPlacement="floating" label="Role zugewiesen" placeholder="Bitte wählen..." value={me[0].role}>
                                {props.roles.map((item) =>
                                (
                                    <IonSelectOption id={"role_" + item.id} key={item.id} value={item.id} selected={me[0].role == item.id}>{item.name}</IonSelectOption>
                                ))}
                            </IonSelect>
                        </IonItem>
                        <IonItem>
                            <IonSelect id="updateUserEvent" labelPlacement="floating" label="Event zugewiesen" placeholder="Bitte wählen..." value={me[0].event_id}>
                                {props.events.map((item, i) => (
                                    <IonSelectOption id={"event_" + item.id} key={i} value={item.id} selected={me[0].event_id == item.id}>{item.eventname}</IonSelectOption>
                                ))}
                            </IonSelect>
                        </IonItem>
                        <IonItem>
                            <IonSelect id="updateUserCompany" labelPlacement="floating" label="Firma zugewiesen" placeholder="Bitte wählen..." value={me[0].company_id}>
                                {props.companies.map((item, i) => (
                                    <IonSelectOption id={"company_" + item.id} key={i} value={item.id} selected={me[0].company_id == item.id}>{item.name}</IonSelectOption>
                                ))}
                            </IonSelect>
                        </IonItem>
                    </IonCardContent>
                </IonCard>
            </>
        );
    } else {
        return (
            <>
                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>Neuen user erstellen</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <IonItem>
                            <IonInput id="newUserName" type="text" labelPlacement="floating" placeholder="Username" value={""}><div slot="label">Username <IonText color="danger">(req)</IonText></div></IonInput>
                        </IonItem>
                        <IonItem>
                            <IonInput id="newUserPassword" type="text" labelPlacement="floating" placeholder="Passwort" value={""}><div slot="label">Passwort <IonText color="danger">(req)</IonText></div></IonInput>
                        </IonItem>
                        <IonItem>
                            <IonSelect id="newUserRole" labelPlacement="floating" label="Role zugewiesen" placeholder="Bitte wählen...">
                                {props.roles.map((item) =>
                                (
                                    <IonSelectOption id={"role_" + item.id} key={item.id} value={item.id}>{item.name}</IonSelectOption>
                                ))}
                            </IonSelect>
                        </IonItem>
                        <IonItem>
                            <IonSelect id="newUserEvent" labelPlacement="floating" label="Event zugewiesen" placeholder="Bitte wählen...">
                                {props.events.map((item, i) => (
                                    <IonSelectOption id={"event_" + item.id} key={i} value={item.id}>{item.eventname}</IonSelectOption>
                                ))}
                            </IonSelect>
                        </IonItem>
                        <IonItem>
                            <IonSelect id="newUserCompany" labelPlacement="floating" label="Firma zugewiesen" placeholder="Bitte wählen...">
                                {props.companies.map((item, i) => (
                                    <IonSelectOption id={"comany_" + item.id} key={i} value={item.id}>{item.name}</IonSelectOption>
                                ))}
                            </IonSelect>
                        </IonItem>
                    </IonCardContent>
                </IonCard >
            </>
        );
    }

}

export default UserData;
