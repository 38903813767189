import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import '@ionic/react/css/core.css';
import { IonButton, IonGrid, IonRow, IonCol, IonCard, IonAlert } from '@ionic/react';
import Ticket from './Ticket';

const Tickets = (props) => {

  const lang = JSON.parse(localStorage.getItem('lang')) || {
    "en": {
      "tickets": "Tickets"
    }
  };

  let currLang = lang[props.lang];

  return (
    <>
      <IonCard class="loggedin">
        <h2>{currLang["tickets"]}</h2>
        {props.tickets.map((item, i) => (
          <Ticket key={i} data={item} language={props.lang} />
        ))}
      </IonCard>
    </>
  );
}

export default Tickets;
