import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import '@ionic/react/css/core.css';
import { IonButton, IonGrid, IonRow, IonCol, IonCard, IonAlert, IonItem, IonCheckbox } from '@ionic/react';

const Ticket = (props) => {

  const lang = JSON.parse(localStorage.getItem('lang')) || {
    "en": {
      "groups": "Gruppen"
    }
  };

  const [eventData, setEventData] = React.useState(
    JSON.parse(localStorage.getItem('event-data')) || false
  );

  const updateTicket = (e) => {
    let oldStoredEventData = JSON.parse(localStorage.getItem('event-data'));
    oldStoredEventData.data.eventTickets[props.data.position].active = e.target.checked;
    localStorage.setItem("event-data", JSON.stringify(oldStoredEventData));
    if(eventData.data.eventTickets[props.data.position].active != oldStoredEventData.data.eventTickets[props.data.position].active){    
      e.target.checked = oldStoredEventData.data.eventTickets[props.data.position].active;
    }
  }

  return (
    <>
      <IonItem><IonCheckbox onClick={updateTicket} checked={props.data.active}>{props.data.title[props.language]}</IonCheckbox></IonItem>
    </>
  );
}

export default Ticket;
