
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import '@ionic/react/css/core.css';
import { IonButton, IonGrid, IonRow, IonCol, IonCard, IonAlert, IonCheckbox, IonItem } from '@ionic/react';

const Group = (props) => {

  const lang = JSON.parse(localStorage.getItem('lang')) || {
    "en": {
      "groups": "Gruppen"
    }
  };

  const [eventData, setEventData] = React.useState(
    JSON.parse(localStorage.getItem('event-data')) || false
  );

  const updateGroup = (e) => {
    let oldStoredEventData = JSON.parse(localStorage.getItem('event-data'));
    oldStoredEventData.data.eventGroups[props.data.position].active = e.target.checked;
    localStorage.setItem("event-data", JSON.stringify(oldStoredEventData));
    if(eventData.data.eventGroups[props.data.position].active != oldStoredEventData.data.eventGroups[props.data.position].active){    
      e.target.checked = oldStoredEventData.data.eventGroups[props.data.position].active;
    }
  }

  return (
    <>
      <IonItem><IonCheckbox onClick={updateGroup} checked={props.data.active}>{props.data.groupname[props.language]}</IonCheckbox></IonItem>
    </>
  );
}

export default Group;
