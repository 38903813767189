import './App.css';
import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { IonContent, IonHeader, IonToolbar, IonTitle, useIonToast, IonButton, IonBackButton, IonButtons } from '@ionic/react';
import Groups from './Components/Groups';
import Tickets from './Components/Tickets';
import Workshops from './Components/Workshops';

const Event = () => {

    const navigate = useNavigate();

    const isLoggedIn = JSON.parse(localStorage.getItem('logged-in')) || false;
    const eventData = JSON.parse(localStorage.getItem('event-data')) || false;

    //console.log(eventData);

    const [present] = useIonToast();
    const presentToast = (custommessage, customclass, customduration = 1500) => {
        present({
            message: custommessage,
            duration: customduration,
            position: 'bottom',
            className: customclass,
        });
    };

    const back = () => {
        navigate("/");
    }

    return (
        <>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="#" onClick={back}></IonBackButton>
                    </IonButtons>
                    {!isLoggedIn && <IonTitle>Logi-Vent Scaner Login</IonTitle>}
                    {isLoggedIn && <IonTitle>Event management</IonTitle>}
                </IonToolbar>
            </IonHeader>

            {isLoggedIn && eventData && <IonContent scroll="true" has-bouncing="true" id='scanView' className="ion-padding">
                <div className="scroll-area">
                    <Groups lang={eventData.data.eventData.language} groups={eventData.data.eventGroups} />
                    <Tickets lang={eventData.data.eventData.language} tickets={eventData.data.eventTickets} />
                    <Workshops lang={eventData.data.eventData.language} workshops={eventData.data.eventWorkshops} />
                </div>
            </IonContent>}
            {!isLoggedIn && <IonContent id='scanView' className="ion-padding"><Link to='/' ><IonButton expand="full">back to login</IonButton></Link></IonContent>}
        </>
    );
}

export default Event;
