import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { IonContent, IonHeader, IonToolbar, IonTitle, useIonToast, IonButton, IonBackButton, IonButtons, IonCard } from '@ionic/react';

import UCard from './UCard';

const UserCards = (props) => {

    const [present] = useIonToast();
    const presentToast = (custommessage, customclass, customduration = 2000) => {
        present({
            message: custommessage,
            duration: customduration,
            position: 'bottom',
            className: customclass,
        });
    };

    return (
        <>
            <IonCard class="loggedin">
                <h2>User</h2>
                {props.users.map((item, i) => (
                    <UCard key={i} data={item} language={props.lang} />
                ))}
            </IonCard>
        </>
    );

}

export default UserCards;
