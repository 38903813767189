import './App.css';
import React, { useState, useEffect } from 'react';
import { QrReader } from 'react-qr-reader';
import Html5QrcodePlugin from './Html5QrcodePlugin.jsx';
import { Html5QrcodeScanner, Html5QrcodeSupportedFormats } from 'html5-qrcode';
import { AES, enc } from 'crypto-js';
import { Link, useNavigate } from "react-router-dom";
import { IonContent, IonHeader, IonToolbar, IonTitle, useIonToast, IonButton, IonBackButton, IonButtons } from '@ionic/react';

const HTML5Reader = (state, reload) => {

    const navigate = useNavigate();

    const isLoggedIn = JSON.parse(localStorage.getItem('logged-in')) || false;
    const eventData = JSON.parse(localStorage.getItem('event-data')) || false;

    const [scannedEntries, addEntry] = useState(JSON.parse(localStorage.getItem('scanned-entry')) || {})
    const [scannedInteressents, addInteressent] = useState(JSON.parse(localStorage.getItem('scanned-interessent')) || {})

    //console.log(scannedEntries, scannedInteressents);

    const [data, setData] = useState('No result');

    const [present] = useIonToast();
    const presentToast = (custommessage, customclass, customduration = 2000) => {
        present({
            message: custommessage,
            duration: customduration,
            position: 'bottom',
            className: customclass,
        });
    };

    useEffect(() => {
        localStorage.setItem('scanned-entry', JSON.stringify(scannedEntries));
    }, [scannedEntries]);

    const logInOutUser = (data, message) => {
        // console.log(data);
        switch (data.message) {
            case "welcome":
                message += " logged in!";
                presentToast(message, "toast-success"); //
                break;
            case "bye":
                message += " logged out!";
                presentToast(message, "toast-success"); //
                break;
        }
    }

    const userForSponsor = (data, message) => {
        // console.log(data);
        switch (data.message) {
            case "saved":
                message += " saved";
                presentToast(message, "toast-success"); //
                break;
            case "exists":
                message = "Participant allready saved!";
                presentToast(message, "toast-error"); //
                break;
        }
    }

    const formatsToSupport = [
        Html5QrcodeSupportedFormats.QR_CODE,
    ];

    const back = () => {
        localStorage.setItem("refresh", true);
        navigate("/");
    }

    const getLastElement = (data) => {
        let lastElement;
        for (lastElement in data);
        return lastElement;
    }

    const testGroup = (allowed, scanned) => {
        let allow = false;
        allowed.forEach(group => {
            if (group.id == scanned.group && group.active) allow = true;
        });
        console.log("Gruppe zugelassen: " + allow);
        return allow;
    }

    const testTicket = (allowed, scanned) => {
        let allow = false;
        allowed.forEach(ticket => {
            if (ticket.id == scanned.ticket && ticket.active) allow = true;
        });
        console.log("Ticket zugelassen: " + allow);
        return allow;
    }

    const testWorkshop = (allowed, scanned) => {
        let allow = false;
        let myWorkshops = scanned.workshops.split(",");
        myWorkshops.forEach(workshop => {
            allowed.forEach(workshopAllowed => {
                if (workshop == workshopAllowed.id && workshopAllowed.active) allow = true;
            });
        });
        let allowsum = 0;
        allowed.forEach(workshopAllowed => {
            if (workshopAllowed.active) allowsum += 1;
        });
        if (myWorkshops.length == 1 && myWorkshops[0] == "" && allowsum == 0) allow = true;
        console.log("Workshop zugelassen: " + allow);
        return allow;
    }

    const hasAccess = (scannedAccess) => {
        const groupAllowed = testGroup(eventData.data.eventGroups, scannedAccess);
        const ticketAllowed = testTicket(eventData.data.eventTickets, scannedAccess);
        const workshpoAllowed = testWorkshop(eventData.data.eventWorkshops, scannedAccess);

        return groupAllowed && ticketAllowed && workshpoAllowed;
    }

    const onNewScanResult = (decodedText, decodedResult) => {
        // console.log(decodedResult.decodedText);
        try {
            if (eventData) {
                let secret = eventData.data.eventData.shortname;
                let jdata = JSON.parse(decodedResult.decodedText);
                let bytes = AES.decrypt(jdata.badge, secret);
                //console.log(bytes);
                const decrypted = bytes.toString(enc.Utf8);
                setData(decrypted);
                let pdata = JSON.parse(decrypted);
                let message = pdata.person.title + " " + pdata.person.firstname + " " + pdata.person.lastname;
                // presentToast(message, "toast-success");
                let id = jdata.user_id.replace(/^0+/, '');
                if (isLoggedIn.role == "admin" || isLoggedIn.role == "scaner") {
                    /*const prepareURL = 'https://logi-vent.congress-registration.com/badge/classes/jsRequestsPython.php?code=hg78tOvkbvgZ0bn5hbvk&id=' + id + '&option=scan&value=5000';
                    fetch(prepareURL)
                        .then(response => response.json())
                        .then(json => logInOutUser(json, message))
                        .catch(error => presentToast(error));*/
                    //console.log(pdata);
                    if (hasAccess(pdata.access)) {
                        if (Object.keys(scannedEntries).length === 0) {
                            let id = jdata.user_id.replace(/^0+/, '');
                            scannedEntries[Date.now()] = id;
                            localStorage.setItem('scanned-entry', JSON.stringify(scannedEntries));
                            addEntry(scannedEntries);
                            let message = "scan successful";
                            presentToast(message, "toast-success"); //
                        } else {
                            let id = jdata.user_id.replace(/^0+/, '');
                            let timeNow = Date.now();

                            let lastKey = getLastElement(scannedEntries);
                            //console.log(lastKey, scannedEntries[lastKey]);

                            if (lastKey != "undefined") {
                                if (id == scannedEntries[lastKey].replace(/^0+/, '')) {
                                    console.log(lastKey)
                                    let diff = (timeNow - lastKey) / 3600;
                                    if (diff >= 1) {
                                        scannedEntries[timeNow] = id;
                                        localStorage.setItem('scanned-entry', JSON.stringify(scannedEntries));
                                        addEntry(scannedEntries);
                                        let message = "scan successful";
                                        presentToast(message, "toast-success"); //
                                    }
                                } else {
                                    scannedEntries[timeNow] = id;
                                    localStorage.setItem('scanned-entry', JSON.stringify(scannedEntries));
                                    addEntry(scannedEntries);
                                    let message = "Create entry";
                                    presentToast(message, "toast-success"); //
                                }
                            } else {
                                scannedEntries[timeNow] = id;
                                localStorage.setItem('scanned-entry', JSON.stringify(scannedEntries));
                                addEntry(scannedEntries);
                                let message = "Create entry";
                                presentToast(message, "toast-success"); //
                            }
                        }
                    } else {
                        let message = "No Access";
                        presentToast(message, "toast-error"); //
                    }
                } else if (isLoggedIn.role == "sponsor") {
                    let id = jdata.user_id.replace(/^0+/, '');
                    let timeNow = Date.now();

                    let lastKey = getLastElement(scannedInteressents);
                    /*const prepareURL = 'https://logi-vent.congress-registration.com/badge/classes/jsRequestsPython.php?code=hg78tOvkbvgZ0bn5hbvk&id=' + isLoggedIn.id + '&option=sponsor&data=' + id;
                    fetch(prepareURL)
                        .then(response => response.json())
                        .then(json => userForSponsor(json, message))
                        .catch(error => presentToast(error));*/
                    if (lastKey) {
                        if (id == scannedInteressents[lastKey].replace(/^0+/, '')) {
                            console.log(lastKey)
                            let diff = (timeNow - lastKey) / 3600;
                            if (diff >= 1) {
                                scannedInteressents[timeNow] = id;
                                localStorage.setItem('scanned-interessent', JSON.stringify(scannedInteressents));
                                addInteressent(scannedInteressents);
                                let message = "scan successful";
                                presentToast(message, "toast-success"); //
                            }
                        } else {
                            scannedInteressents[timeNow] = jdata.user_id;
                            localStorage.setItem('scanned-interessent', JSON.stringify(scannedInteressents));
                            presentToast("scan successful", "toast-success", 3000); //3000
                            addInteressent(scannedInteressents);
                        }

                    } else {
                        scannedInteressents[timeNow] = jdata.user_id;
                        localStorage.setItem('scanned-interessent', JSON.stringify(scannedInteressents));
                        presentToast("scan successful", "toast-success", 3000); //3000
                        addInteressent(scannedInteressents);
                    }
                }
            } else {
                let message = "Server error ocured";
                presentToast(message, "toast-error");  //
            }
        } catch (e) {
            let message = "Code invalid!";
            presentToast(message, "toast-error"); //
        }
    };

    return (
        <>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="#" onClick={back}></IonBackButton>
                    </IonButtons>
                    {!isLoggedIn && <IonTitle>Logi-Vent Scaner Login</IonTitle>}
                    {isLoggedIn && <IonTitle>{isLoggedIn.company} Leadscaner</IonTitle>}
                </IonToolbar>
            </IonHeader>
            {isLoggedIn && <IonContent id='scanView' className="ion-padding">
                <Html5QrcodePlugin
                    fps={1}
                    qrbox={250}
                    disableFlip={false}
                    qrCodeSuccessCallback={onNewScanResult}
                    formatsToSupport={formatsToSupport}
                    showTorchButtonIfSupported={true}
                    showZoomSliderIfSupported={false}
                    rememberLastUsedCamera={true}
                />
            </IonContent>}
            {!isLoggedIn && <IonContent id='scanView' className="ion-padding"><Link to='/' ><IonButton expand="full">back to login</IonButton></Link></IonContent>}
        </>
    );

}

export default HTML5Reader;
