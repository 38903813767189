import './App.css';
import {
  Routes,
  Route,
} from "react-router-dom";
import React, { useState, useEffect } from 'react';

import '@ionic/react/css/core.css';
import { setupIonicReact, useIonToast } from '@ionic/react';

import HTML5Reader from './HTML5Reader';
import User from './User';
import UserEditNew from './UserEditNew';
import Event from './Event';
import Login from './Login';
import Visitor from './Visitor';

import usePreventZoom from './usePreventZoom'

setupIonicReact();

function App() {

  usePreventZoom();

  const lang = {
    "de": {
      "username": "Benutzermane",
      "password": "Passwort",
      "required": "erforderlich",
      "password-user-empty": "Benutzername und/oder Password leer",
      "login-fail": "Falsche Logindaten",
      "welcome": "Willkommen",
      "scan": "Scannen",
      "options": "Einstellungen",
      "manage-event": "Event verwalten",
      "manage-user": "Benutzer verwalten",
      "scanned-entries": "Gespeicherter Eingang",
      "scanned-interessents": "Gespeicherte Interessenten",
      "upload": "Hochladen",
      "delete": "Löschen",
      "popup-warning": "Achtung!",
      "delete-message": "Durch Klicken auf LÖSCHEN werden alle Daten restlos gelöscht!",
      "popup-back": "Zurück",
      "delete-confirm": "Löschen",
      "logout-message": "Lokal gespeicherte Daten vorhanden, Logout erst nach dem Hochladen möglich!",
      "logout-confirm": "Hochladen und Ausloggen",
      "groups": "Gruppen",
      "tickets": "Tickets",
      "workshops": "Workshops"
    },
    "en": {
      "username": "Username",
      "password": "Password",
      "required": "required",
      "password-user-empty": "Username and/or password empty",
      "login-fail": "Wrong Logindata",
      "welcome": "Welcome",
      "scan": "Scan",
      "options": "Options",
      "manage-event": "Manage Event",
      "manage-user": "Manage User",
      "scanned-entries": "Scanned Entries",
      "scanned-interessents": "Scanned Interessents",
      "upload": "Upload",
      "delete": "Delete",
      "popup-warning": "Warning!",
      "delete-message": "By pressind DELETE, stored data will be permanently deleted!",
      "popup-back": "Back",
      "delete-confirm": "Delete",
      "logout-message": "Local stored data exists, please upload data before logout!",
      "logout-confirm": "Upload and Logout",
      "groups": "Groups",
      "tickets": "Tickets",
      "workshops": "Workshops"
    }
  }

  localStorage.setItem("lang",JSON.stringify(lang));

  const [present] = useIonToast();
  const presentToast = (custommessage, customclass, customduration = 1500) => {
    present({
      message: custommessage,
      duration: customduration,
      position: 'bottom',
      className: customclass,
    });
  };

  const [state, setState] = useState(() => {
    return {
      since: undefined,
      online: navigator.onLine,
      ...getNetworkConnectionInfo(),
    };
  });

  function getNetworkConnection() {
    return (
      navigator.connection ||
      navigator.mozConnection ||
      navigator.webkitConnection ||
      null
    );
  }

  function getNetworkConnectionInfo() {
    const connection = getNetworkConnection(); if (!connection) {
      return {};
    }
    return {
      rtt: connection.rtt,
      type: connection.type,
      saveData: connection.saveData,
      downLink: connection.downLink,
      downLinkMax: connection.downLinkMax,
      effectiveType: connection.effectiveType,
    };
  }

  useEffect(() => {
    const handleOnline = () => {
      setState((prevState) => ({
        ...prevState,
        online: true,
        since: new Date().toString(),
      }));
      presentToast("online", "toast-success", 10000); //
    };
    const handleOffline = () => {
      setState((prevState) => ({
        ...prevState,
        online: false,
        since: new Date().toString(),
      }));
      presentToast("offline", "toast-danger", 10000); //
    };
    const handleConnectionChange = () => {
      setState((prevState) => ({
        ...prevState,
        ...getNetworkConnectionInfo(),
      }));
    };
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline); 

    const connection = getNetworkConnection(); 
    connection?.addEventListener("change", handleConnectionChange); return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
      connection?.removeEventListener("change", handleConnectionChange);
    };
  }, []);

  window.addEventListener(
    "touchmove",
    function (event) {
      if (event.touches.length > 1 && event.scale !== 1) {
        event.preventDefault();
        //presentToast(event.touches.length, "");
        //event.stopImmediatePropagation();
      }
    },
    { passive: false }
  );

  return (
    <>
      <Routes>
        <Route path="/scan" element={HTML5Reader(state)} />
        <Route path="/user" element={User()} />
        <Route path="/visitor" element={Visitor()} />
        <Route path="/user/en" element={UserEditNew()}/>
        <Route path="/event" element={Event()} />
        <Route index path="/" element={Login(state)} />
        <Route path="*" element={Login(state)} />
      </Routes>
    </>
  );
}

export default App;
