import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import '@ionic/react/css/core.css';
import { IonButton, IonGrid, IonRow, IonCol, IonCard, IonAlert, useIonToast } from '@ionic/react';

const UploadEntries = (state) => {
  const navigate = useNavigate();
  const [scannedEntries, setEntries] = useState(JSON.parse(localStorage.getItem('scanned-entry')) || {});
  const [showAlert, setShowAlert] = useState(false);
  const eventData = JSON.parse(localStorage.getItem('event-data')) || false;

  const isLoggedIn = JSON.parse(localStorage.getItem('logged-in')) || false;

  const [present] = useIonToast();
  const presentToast = (custommessage, customclass, customduration = 2000) => {
      present({
          message: custommessage,
          duration: customduration,
          position: 'bottom',
          className: customclass,
      });
  };

  const lang = JSON.parse(localStorage.getItem('lang')) || {
    "en": {
      "scanned-entries": "Scanned Entries",
      "scanned-interessents": "Scanned Interessents",
      "upload": "Upload",
      "delete": "Delete",
      "popup-warning": "Warning!",
      "delete-message": "By pressing DELETE, stored data will be permanently deleted!",
      "popup-back": "Back",
      "delete-confirm": "Delete"
    }
  };

  let currLang = lang["en"];
  if (eventData) {
    currLang = lang[eventData.data.eventData.language];
  }

  const uploadEntry = () => {
    console.log(scannedEntries);

    let prepareURL = 'https://logi-vent.congress-registration.com/badge/classes/jsRequestsPython.php?code=hg78tOvkbvgZ0bn5hbvk&id=1&option=upload&value=entries&scaner_id=' + isLoggedIn.id + '&data=' + JSON.stringify(scannedEntries);
    fetch(prepareURL)
      .then(response => response.json())
      .then(json => uploadResponse(json))
      .catch(error => presentToast("Can't upload data to server!", "toast-error", 5000)); // 5000
  }

  const deletedEntry = () => {
    setShowAlert(true);
    console.log(scannedEntries);
  }

  const refreshPage = () => {
    navigate(0);
  }

  if (localStorage.getItem("refresh")) {
    console.log("refresh");
    localStorage.removeItem("refresh");
    refreshPage();
  }

  const uploadResponse = (input) => {
    if (input.response.status == "success") {
      localStorage.setItem('scanned-entry', '{}');
      setEntries({});
    } else {
      setEntries(input.response.resume);
    }
    //console.log(response.response);
  }

  const doDelete = (status) => {
    //console.log(status);
    if (status == "confirm") {
      localStorage.setItem('scanned-entry', '{}');
      setEntries({});
    }
    setShowAlert(false);
  }

  return (
    <>
      {Object.keys(scannedEntries).length > 0 && <IonCard class="loggedin">
        <h2>{currLang["scanned-entries"]}</h2>
        <IonGrid>
          <IonRow>
            <IonCol><IonButton expand="full" style={{ height: '100%' }} onClick={uploadEntry}>{currLang["upload"]}</IonButton></IonCol>
            <IonCol><IonButton color="danger" expand="full" style={{ height: '100%' }} onClick={deletedEntry}>{currLang["delete"]}</IonButton></IonCol>
          </IonRow>
        </IonGrid>
      </IonCard>}
      {showAlert ? <IonAlert
        header={currLang["popup-warning"]}
        message={currLang["delete-message"]}
        isOpen={showAlert}
        buttons={[
          {
            text: currLang["popup-back"],
            role: 'cancel',
            handler: () => {

            },
          },
        ]}
        onDidDismiss={({ detail }) => doDelete(detail.role)}
      ></IonAlert> : null}
    </>
  );
}

export default UploadEntries;
