import './App.css';
import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { IonContent, IonHeader, IonToolbar, IonTitle, useIonToast, IonButton, IonBackButton, IonButtons, IonCard, IonFooter, IonModal, IonList, IonItem, IonLabel, withIonLifeCycle  } from '@ionic/react';

import { format } from 'date-fns';

const Visitor = () => {

    const navigate = useNavigate();

    const [userId, setUserId] = useState(JSON.parse(localStorage.getItem('scaner-id')) || 0);

    const isLoggedIn = JSON.parse(localStorage.getItem('logged-in')) || false;
    let visitors = new Array({"id":"","ts":"2024","salutation_id":"","title":"","firstname":"empty","lastname":"","email":""});
    if(localStorage.getItem('booth-visitors') != "undefined"){
        visitors = JSON.parse(localStorage.getItem('booth-visitors'))
    }

    // console.log(visitors);

    const [present] = useIonToast();
    const presentToast = (custommessage, customclass, customduration = 2000) => {
        present({
            message: custommessage,
            duration: customduration,
            position: 'bottom',
            className: customclass,
        });
    };

    const back = () => {
        localStorage.removeItem('booth-visitors')
        navigate("/", { replace: true });
    }

    return (
        <>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="#" onClick={back}></IonBackButton>
                    </IonButtons>
                    {!isLoggedIn && <IonTitle>Logi-Vent Scaner Login</IonTitle>}
                    {isLoggedIn && <IonTitle>Booth visitors</IonTitle>}
                </IonToolbar>
            </IonHeader>
            {isLoggedIn && <IonContent scroll="true" has-bouncing="true" id='scanView' className="ion-padding">
                <div className="scroll-area">
                    <IonList>
                        {visitors && visitors.map((item, i) => (
                            <IonItem key={i}>
                                <IonLabel>
                                    <div className='row-space-between'>
                                        <h2 className='inline'>{((item.salutation_id == 1) ? "Mr. " : "") + ((item.salutation_id == 2) ? "Ms. " : "") + ((item.title != "") ? item.title + " " : "") + item.firstname + " " + item.lastname}</h2>
                                        {item.firstname != "empty" && <p className='inline'>{format(new Date(item.ts), 'yyyy/MM/dd kk:mm:ss')}</p>}
                                    </div>
                                    <p>{item.email}</p>
                                </IonLabel>
                            </IonItem>
                        ))}
                    </IonList>
                </div>
            </IonContent>}
            {!isLoggedIn && <IonContent id='scanView' className="ion-padding"><Link to='/' ><IonButton expand="full">back to login</IonButton></Link></IonContent>}
        </>
    );

}

export default Visitor;
