import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import '@ionic/react/css/core.css';
import { IonButton, IonGrid, IonRow, IonCol, IonCard, IonAlert } from '@ionic/react';
import Group from './Group'

const Groups = (props) => {

    const lang = JSON.parse(localStorage.getItem('lang')) || {
        "en": {
            "groups": "Gruppen"
        }
    };

    let currLang = lang[props.lang];

    return (
        <>
            <IonCard class="loggedin">
                <h2>{currLang["groups"]}</h2>
                {props.groups.map((item, i) => (
                    <Group key={i} data={item} language={props.lang} />
                ))}
            </IonCard>
        </>
    );
}

export default Groups;
