import './App.css';
import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { IonContent, IonHeader, IonToolbar, IonTitle, useIonToast, IonButton, IonBackButton, IonButtons, IonCard, IonFooter, IonModal, IonList, IonItem, IonLabel } from '@ionic/react';

import UserCards from './Components/UserCards';
import UserData from './Components/UserData';

const User = () => {

    const navigate = useNavigate();

    const [userId, setUserId] = useState(JSON.parse(localStorage.getItem('scaner-id')) || 0);

    const isLoggedIn = JSON.parse(localStorage.getItem('logged-in')) || false;
    const eventData = JSON.parse(localStorage.getItem('event-data')) || false;
    const allUser = JSON.parse(localStorage.getItem('all-user')) || {};
    const allEvents = JSON.parse(localStorage.getItem('all-events')) || {};

    const [present] = useIonToast();
    const presentToast = (custommessage, customclass, customduration = 2000) => {
        present({
            message: custommessage,
            duration: customduration,
            position: 'bottom',
            className: customclass,
        });
    };

    const newUser = () => {
        localStorage.setItem('scaner-id',0);
        navigate("/user/en", { replace: true });
    }

    const back = () => {
        if (userId > 0) {
            localStorage.setItem("scaner-id", 0);
            setUserId(0);
            navigate("/user", { replace: true });
        } else {
            navigate("/", { replace: true });
        }
    }

    return (
        <>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="#" onClick={back}></IonBackButton>
                    </IonButtons>
                    {!isLoggedIn && <IonTitle>Logi-Vent Scaner Login</IonTitle>}
                    {isLoggedIn && <IonTitle>User management</IonTitle>}
                </IonToolbar>
            </IonHeader>
            {isLoggedIn && <IonContent scroll="true" has-bouncing="true" id='scanView' className="ion-padding">
                <div className="scroll-area">
                    <UserCards users={allUser.data} events={allEvents} />
                </div>
            </IonContent>}
            {!isLoggedIn && <IonContent id='scanView' className="ion-padding"><Link to='/' ><IonButton expand="full">back to login</IonButton></Link></IonContent>}
            {isLoggedIn && <IonFooter>
                <IonToolbar class="loggedin">
                    <IonButton expand="full" id="open-data" color="primary" onClick={newUser}>New User</IonButton>
                </IonToolbar>
            </IonFooter>}
        </>
    );

}

export default User;
